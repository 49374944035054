import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import yellowOne from "../images/1-yellow.svg";
import yellowTwo from "../images/2-yellow.svg";
import yellowThree from "../images/3-yellow.svg";

const BrandingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
        mediaDetails {
          height
          width
        }
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      yourBrandImg: wpMediaItem(title: { eq: "Copywriting landing page" }) {
        altText
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
        link
      }
      linvaLogoImg: wpMediaItem(title: { eq: "lingvahive-logo" }) {
        altText
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
        link
      }
      RyanImg: wpMediaItem(title: { eq: "Ryan-Dixon-Property-Logo-Master" }) {
        altText
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
        link
      }
      TootLogoImg: wpMediaItem(title: { eq: "Toot-Hill-Master-Logo 1" }) {
        altText
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
        link
      }

      wpSeoPage(title: { eq: "Copywriting" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
            mediaDetails {
              height
              width
            }
            link
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
        link
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Copywriting",
        item: {
          url: `${siteUrl}/copywriting`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/copywriting`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section>
          <Hero
            button1="primary-link-dark"
            button1Text="Talk to an expert"
            button1Link="#form"
            button1Var="white"
            button2="white-link-yellow"
            button2Text="Get in touch"
            button2Link="/contact-us"
            button2Var="primary"
            button3={null}
            backgroundImage={data.heroImg.gatsbyImage}
            title={`<span class="text-secondary">Copywriting</span>`}
            description="Engage with your customers with professional copywriting on your website."
          />
        </section>
        {/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#what-we-do"
								>
									What we do
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
        <section id="intro" className="bg-light-grey py-5 py-lg-7">
          <Container>
            <Row className="align-items-center gx-lg-6 gx-xl-7">
              <Col lg={6} className=" ">
                <h2 className="display-5 text-primary mb-5">
                  Create persuasive content to make your customers take action
                </h2>
                <p>
                  Stuck on where to start with the copy on your website? RJM
                  Digitals team of experienced copywriters are here to lend you
                  a helping hand.
                </p>
                <p>
                  When it comes to developing the copy for your website, we know
                  how important it is to capture your brand's unique voice and
                  convey your message effectively. Our talented copywriters are
                  skilled in creating effective and compelling content that
                  targets your ideal audience, drives conversions and improves
                  your rankings on search engine results pages.
                </p>
                <p>
                  Combine our copywriting services with our proven SEO services
                  to achieve the best results from your website. Our Copywriters
                  are specialists in SEO best practices and know how to
                  incorporate the most relevant keywords and phrases seamlessly
                  into your content. We will optimise your copy to help your
                  site reach the top position on Google search results and make
                  it easier for your customers to find you online.
                </p>
                <p>
                  Whether you're looking to be informative, thought provoking,
                  or be persuasive, we have the copywriting expertise to help
                  you achieve your goals.
                </p>

                <Button
                  className=" w-100 cta-btn w-md-auto me-xl-4  fs-5 white-link-yellow py-2 px-4 mb-5 mb-lg-0 mt-4 "
                  variant="primary"
                  as={Link}
                  to="/seo"
                >
                  Our SEO services
                </Button>
                <Button
                  className=" w-100 cta-btn w-md-auto fs-5 d-none d-xl-inline-block white-link-yellow py-2 px-4 mb-5 mb-lg-0 mt-4 "
                  variant="primary"
                  as={Link}
                  to="/contact-us"
                >
                  Get in touch
                </Button>
              </Col>
              <Col lg={6}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.yourBrandImg.gatsbyImage}
                    alt={data.yourBrandImg.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section
					id="what-we-do"
					className="bg-light-grey position-relative pb-5 pb-lg-7"
				>
					<div className="position-relative bg-dark-grey">
						<Container className="pb-10 pt-7">
							<Row className="align-items-center ">
								<Col lg={6} className=" ">
									<h2 className=" text-white display-5 mb-4">
										How we can help you
									</h2>
									<p className="text-white">
										We have built many brands that business owners and customers
										love in equal measure. Whether you're building a brand new
										brand or building upon an existing one, we can help you.
									</p>
									<p className="text-white">
										Here are some examples of branding work we have completed
										for some of our clients.
									</p>
								</Col>
							</Row>
						</Container>
					</div>
					<div style={{ top: "-5rem" }} className="position-relative">
						<Container>
							<Row className="g-6">
								<Col lg={4}>
									<div
										className="bg-white d-flex justify-content-center px-4 align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.linvaLogoImg.gatsbyImage}
												alt={data.linvaLogoImg.altText}
											/>
										</div>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className="bg-white d-flex justify-content-center px-4 align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.RyanImg.gatsbyImage}
												alt={data.RyanImg.altText}
											/>
										</div>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className="bg-white d-flex justify-content-center px-4 align-items-center"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
										}}
									>
										<div className="d-block">
											<GatsbyImage
												className="w-100 "
												image={data.TootLogoImg.gatsbyImage}
												alt={data.TootLogoImg.altText}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section> */}
        {/* <Logos /> */}

        {/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Branding" />
				</section> */}
        {/* <div id="faq">
					<Faq page="Branding" />
				</div> */}

        <section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
          <Container className="mb-8">
            <Row className="justify-content-between align-items-center">
              <Col md={9} lg={8}>
                <h2 className="display-2 text-uppercase ssp-bold text-white mb-lg-5">
                  Main benefits of
                  <span>
                    <br />{" "}
                    <span className="text-secondary">
                      professional copywriting
                    </span>
                  </span>
                </h2>
              </Col>
              {/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
            </Row>
            <Row className="gx-xl-9 gy-5 justify-content-center">
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={yellowOne} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Targeting</h3>
                  <p className="text-white">
                    Finding the best tone of voice that engages with your
                    audience is crucial, our experienced copywriters know how to
                    write content that will not only attract your ideal
                    customers but will also create a good first impression.
                  </p>
                </div>
              </Col>
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={yellowTwo} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Better for SEO</h3>
                  <p className="text-white">
                    Google prefers conversational language that is written to
                    engage with your specific audience , rather than copy that
                    is written for its algorithm.
                  </p>
                </div>
              </Col>
              <Col className="position-relative " md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute w-100 text-center h-100"
                >
                  <img src={yellowThree} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Competitive advantage</h3>
                  <p className="text-white">
                    Businesses that write the copy for their websites often end
                    up sounding like everybody else in their market. However,
                    with professional copy written by an expert, you can stand
                    out from the crowd with content that speaks to your ideal
                    customer.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5 py-lg-7  bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                {" "}
                <h2 className="display-5 text-primary mb-3">
                  Talk to an expert
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your copywriting project.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="py-5 pb-lg-7" id="pricing">
					<Container>
						<Row>
							<Col>
								<h2 className=" text-center  display-5 mb-5 mt-5   pb-5 text-primary">
									PRICING
								</h2>
							</Col>
						</Row>
						<Row className="justify-content-center ">
							<PricingCardTraining
								btnColour="secondary"
								title="AD - HOC"
								subText="For specific queries about SEO or Google Ads for your website. "
								price="£55"
								hourly
								middle
							/>
							<PricingCardTraining
								btnColour="light-blue"
								title="MONTHLY"
								subText="For businesses who are looking for a long term support and training on upskilling their staff"
								price="£350"
								monthly
								middle
							/>
						</Row>
					</Container>
				</section> */}
        <OSSection
          link1="/web-design"
          text1="WEB DESIGN"
          link2="/e-commerce-websites"
          text2="E-COMMERCE"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1.altText}
        />
      </Layout>
    </>
  );
};

export default BrandingPage;
